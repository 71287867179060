import React, { useState, useEffect, useRef } from 'react'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import 'moment/locale/fr'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { useForm } from 'react-hook-form'
import { language, origin, location, originUrl } from '../utils'
import Button from './UI/Button'
import Title from './UI/Title'
import Label from './UI/Label'
import { getStocks, createEvent } from '../api'
import I18n from './UI/I18n'
import Checkbox from './UI/Checkbox'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import flags from 'react-phone-number-input/flags'
import { getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import { FiChevronDown } from 'react-icons/fi'

const isIframe = () => (typeof window !== `undefined`) ? window.self !== window.top : false

moment.locale('en')

function EventDate({ register, setValue, value }) {
  const [focus, setFocus] = useState(undefined)
  const [date, setDate] = useState(undefined)
  const [stock, setStock] = useState(undefined)
  useEffect(() => {
    getStocks().then(s => setStock(s.map(s => ({ ...s, from: moment(s.from), to: moment(s.to) }))))
    register({ name: 'date' })
  }, [])
  useEffect(() => {
    if (value === null) setDate(undefined)
  }, [value])
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: `
        .DateInput_input { outline: none; appearance: none; background-color: #fff; border: 1px solid #e3e5e8; border-radius: 0.125rem; padding-top: 0.5rem; padding-right: 0.75rem; padding-bottom: 0.5rem; padding-left: 0.75rem; line-height: 1.5; box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%); font-weight: 300; font-size: 1rem; }
        .DateInput_input__focused { box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%); border: 1px solid #c7cbd1; }
        .CalendarDay__selected, .CalendarDay__selected:hover { background: #4da1a9; border-color: #4da1a9; }
        .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover { background: #4da1a9; border-color: #4da1a9 }
      ` }} />
      {stock &&
        <SingleDatePicker
          date={date}
          focused={focus}
          onFocusChange={({ focused }) => setFocus(focused)}
          numberOfMonths={1}
          onDateChange={d => { setValue('date', d ? d.format() : null); setDate(d) }}
          firstDayOfWeek={1}
          isOutsideRange={day =>
            day.isSameOrBefore(moment().add(5, 'days')) ||
            (stock.find(s => day.set('hours', 12).isBetween(s.from, s.to.clone().add(1, 'day'))) || { left: 10 }).remaining <= 0
          }
          initialVisibleMonth={() => moment().add(2, 'months')}
          daySize={40}
          placeholder="DD/MM/YYYY"
          renderMonthText={(v, w) => {
            console.log(v, w)
            return (
              <div className={[5, 6, 7].includes(v.month()) && `-mt-4`}>
                {[5, 6, 7].includes(v.month()) &&
                <>
                  <div className="inline-block text-center bg-green font-normal text-xs text-white mx-auto rounded px-1 -mb-1"><I18n>form.contact.highSeason</I18n></div>
                  <br />
                  </>
                }
                {v.format('MMMM YYYY')}
              </div>
            )
          } }
          hideKeyboardShortcutsPanel
          block
          noBorder />
      }
    </>
  )
}

const CountrySelect = ({ value, onChange, ...rest }) => {
  const ref = useRef()
  const labels = en
  return (
    <div className="h-6 flex items-center">
      {React.createElement(flags[value] || 'div', { className: 'absolute shadow w-8 rounded pointer-events-none' })}
      <select
        {...rest}
        ref={ref}
        value={value}
        className="bg-white border-0 outline-none w-12 px-6 ml-1 appearance-none"
        onChange={event => onChange(event.target.value || undefined)}>
        {['GB'].map(country => (
          <option key={country} value={country}>
            {labels[country]} +{getCountryCallingCode(country)}
          </option>
        ))}
      </select>
      <FiChevronDown className="absolute ml-8 pointer-events-none" />
    </div>
  )
}

export default function Form() {
  const { getValues, setValue, register, trigger: validate, errors } = useForm({ mode: 'onChange' })
  useEffect(() => {
    register({ name: 'confi' }, { validate: { checked: v => v || 'You must accept terms to continue' } })
    register({ name: 'phone' }, { required: true, pattern2: /^(\+33|\+32|\+352|\+41|\+44|0)*\d{8,10}$/ })
  }, [])
  const prepare = async () => {
    if (window.gtag)
      window.gtag('event', 'conversion', {'send_to': 'AW-951050257/Cc9XCPiRiYwBEJHAv8UD'})
    const event = await createEvent({ ...getValues(), optin: true, origin: origin(), originUrl: originUrl() || window.location.href })
    const redirect = !window.location.search.match(/redirect=false/)
    if (isIframe()) {
      window.top.postMessage({ event: 'submit', target: `${window.location.origin}/event/${event.id}_${event.token}` }, '*')
      setTimeout(() => window.parent.location = `${window.location.origin}/event/${event.id}_${event.token}`, 250)
    }
    if (redirect)
      window.location = `${window.location.origin}/event/${event.id}_${event.token}`
    else {
      alert('Event created')
      window.location.reload()
    }
  }
  const error = Object.keys(errors).length > 0
  console.log(errors, getValues())
  return (
    <div
      className="max-w-screen-sm mx-0 sm:mx-auto">
        <div className="lg:px-5">
          <div className="flex flex-wrap w-full m-auto">
            <Label title={<I18n>form.contact.date</I18n>} error={errors.date}>
              <EventDate register={register} setValue={(k, v) => { setValue(k, v); validate() }} value={getValues().date} />
            </Label>
            {/* <Label title="&nbsp;">
              <div className="flex justify-start items-center text-sm text-left leading-tight mt-2">
                <Checkbox checked={!getValues().date} onClick={() => { setValue('date', null); validate() } } className="mr-2 lg:mr-4" />
                <div className="text-gray-500">
                  Je n'ai pas encore de date
                  <small className="block mt-1 -mb-4 opacity-75">Vous pouvez la choisir jusqu'à 1 mois avant votre événement</small>
                </div>
              </div>
            </Label> */}
          </div>
          <div className="flex flex-wrap w-full m-auto">
            <Label title={<I18n>form.contact.firstname</I18n>} error={errors.firstname}>
              <input type="text" className="form-input text-base w-full" name="firstname" ref={register({ required: true, min: 3 })} />
            </Label>
            <Label title={<I18n>form.contact.lastname</I18n>} error={errors.lastname}>
              <input type="text" className="form-input text-base w-full" name="lastname" ref={register({ required: true, min: 3 })} />
            </Label>
          </div>
          <div className="flex flex-wrap w-full m-auto">
            <Label title={<I18n>form.contact.email</I18n>} error={errors.email}>
              <input type="text" className="form-input text-base w-full" name="email" ref={register({ required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
            </Label>
            <Label for="name" title={<I18n>form.contact.phone</I18n>} error={errors.phone} noLabel>
              <style dangerouslySetInnerHTML={{ __html: `
                .PhoneInputInput { outline: none; border: none; width: 100%; }
                .PhoneInputCountry { width: 35 }
                .PhoneInputCountryIcon--border { border-radius: 3px; overflow: hidden; box-shadow: 0 1px 4px rgb(0, 0, 0, 0.2); }
                .PhoneInputCountrySelect:focus, .PhoneInputCountryIcon--border { outline: none !important; border: none; }
                .PhoneInputCountrySelectArrow { --PhoneInputCountrySelectArrow-color: black }
              ` }} />
              <PhoneInput
                className="form-input text-base w-full"
                countrySelectComponent={CountrySelect}
                defaultCountry="GB"
                onlyCountries={['gb']}
                value={getValues().phone}
                onChange={v => { setValue('phone', v); validate() }}
                // ref={register({ required: true, pattern: /^(?:(?:\+|00)33|32|352|41|0)\s*[1-9](?:[\s]*\d{2}){4}$/ })}
                />
              {/* <input type="text" className="form-input text-base w-full" name="phone" ref={register({ required: true, pattern: /^(?:(?:\+|00)33|32|352|41|0)\s*[1-9](?:[\s]*\d{2}){4}$/ })} /> */}
            </Label>
          </div>
          <div className="flex justify-start items-center px-1 lg:px-3 w-full my-4 text-sm text-left leading-tight">
            <Checkbox checked={getValues().confi} onClick={() => { setValue('confi', !(getValues().confi)); validate() } } className="mr-2 lg:mr-4" />
            <div className="text-gray-500">
              <I18n html>form.contact.confidentiality</I18n>
              {errors.confi &&
                <div className="leading-none font-light text-xs text-red opacity-50">
                  {errors.confi.message}
                </div>
              }
            </div>
          </div>
          <Button className="mx-auto my-2" disabled={error} onClickDisabled={validate} onClick={prepare}><I18n>form.contact.next</I18n></Button>
        </div>
    </div>
  )
}